import React from 'react';

import Layout from '../components/layouts/content';
import SEO from '../components/seo';
import * as DesignSystem from 'src/components/design-system';
import Logo from 'src/components/logo';

const NotFoundPage = () => (
  <Layout>
    <SEO title="Page non trouvée" />
    <div className="flex flex-row items-center">
      <Logo className="h-40" />
      <div>
        <DesignSystem.H2>Page non trouvée</DesignSystem.H2>
        <DesignSystem.P className="mt-4">
          Oups... Nous ne sommes pas en mesure d'afficher la page que vous
          cherchez. C'est probablement une erreur de notre part. 🙊
        </DesignSystem.P>
        <DesignSystem.P className="mt-2">
          Nous vous invitons à retourner sur la{' '}
          <DesignSystem.Link to="/">page d'accueil</DesignSystem.Link> ou à
          suivre un lien dans la barre de navigation. ☝️
        </DesignSystem.P>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
